import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button } from './components/ui/button';
import { Textarea } from './components/ui/textarea';
import { Slider } from './components/ui/slider';
import { io, Socket } from 'socket.io-client';

interface ApiResponse {
  text: string;
  question: string;
  chatId: string;
  chatMessageId: string;
  isStreamValid: boolean;
  sessionId: string;
}

interface QAPair {
  question: string;
  answer: string;
}

const App: React.FC = () => {
  const [inputText, setInputText] = useState<string>('');
  const [outputText, setOutputText] = useState<string>('');
  const [annotatedText, setAnnotatedText] = useState<string>('');
  const [isStreaming, setIsStreaming] = useState<boolean>(false);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [wordsPerSecond, setWordsPerSecond] = useState<number>(10);
  const [currentSpeaker, setCurrentSpeaker] = useState<string>('');
  const [qaPairs, setQAPairs] = useState<QAPair[]>([]);
  const [currentQAIndex, setCurrentQAIndex] = useState<number>(0);
  const [sentenceCount, setSentenceCount] = useState<number>(0);
  const [isSocketConnected, setIsSocketConnected] = useState<boolean>(false); // New state variable

  const wordIndex = useRef<number>(0);
  const words = useRef<string[]>([]);
  const lastProcessedSentenceIndex = useRef<number>(0);
  const completeSentences = useRef<string[]>([]);
  const socketRef = useRef<Socket | null>(null);
  const microphoneRef = useRef<MediaRecorder | null>(null);

  const SENTENCES_TO_PROCESS = 5;
  const CONTEXT_SENTENCES = 3;

  const splitIntoSentences = (text: string): string[] => {
    return text.match(/[^.!?]+[.!?]+/g) || [];
  };

  const lastQueryTime = useRef<number>(0);
  const MIN_QUERY_INTERVAL = 1000;

  const query = useCallback(async (data: { question: string }): Promise<ApiResponse | null> => {
    const now = Date.now();
    if (now - lastQueryTime.current < MIN_QUERY_INTERVAL) {
      console.log('Rate limit: Too many requests. Skipping this call.');
      return null;
    }

    try {
      const response = await fetch(
        'https://app.cognevo.co.uk/api/v1/prediction/4be7bbde-1252-4dff-9f3c-1607af881bf6',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        }
      );
      const result = await response.json();
      lastQueryTime.current = now;
      return result;
    } catch (error) {
      console.error('API call failed:', error);
      return null;
    }
  }, []);

  const processText = useCallback(
    async (sentences: string[]): Promise<string> => {
      console.log(`processText called with sentences:`, sentences);

      const contextPairs = qaPairs.slice(-CONTEXT_SENTENCES);
      let contextText = contextPairs
        .map((pair) => `**interviewer** ${pair.question}\n**candidate** ${pair.answer}`)
        .join('\n');

      const fullText = `${contextText}\n${sentences.join(' ')}`;

      const result = await query({ question: fullText });
      if (result && result.text) {
        let newAnnotatedText = result.text;
        let newCurrentSpeaker = '';

        const parts = newAnnotatedText.split(/\*\*(interviewer|candidate)\*\*/);

        let newQAPairs = [...qaPairs];
        let lastCompletedPairIndex = -1;

        for (let i = 1; i < parts.length; i += 2) {
          const speaker = parts[i];
          const content = parts[i + 1].trim();

          if (speaker === 'interviewer') {
            newQAPairs.push({ question: content, answer: '' });
          } else if (speaker === 'candidate') {
            if (newQAPairs.length > 0) {
              const lastPair = newQAPairs[newQAPairs.length - 1];
              lastPair.answer += (lastPair.answer ? ' ' : '') + content;

              if (i === parts.length - 2) {
                lastCompletedPairIndex = newQAPairs.length - 1;
              }
            }
          }

          newCurrentSpeaker = speaker;
        }

        if (lastCompletedPairIndex !== -1) {
          const completedPair = newQAPairs[lastCompletedPairIndex];
          console.log('Completed QA Pair:', {
            question: completedPair.question,
            answer: completedPair.answer,
          });
        }

        setQAPairs(newQAPairs);
        setAnnotatedText(newAnnotatedText);
        setCurrentSpeaker(newCurrentSpeaker);

        return newAnnotatedText;
      }
      return sentences.join(' ');
    },
    [qaPairs, query]
  );

  const processTranscription = useCallback(
    (text: string) => {
      const sentences = splitIntoSentences(text);
      if (sentences.length > completeSentences.current.length) {
        completeSentences.current = sentences;
        setSentenceCount(sentences.length);

        if (sentences.length >= lastProcessedSentenceIndex.current + SENTENCES_TO_PROCESS) {
          const sentencesToProcess = sentences.slice(lastProcessedSentenceIndex.current);
          processText(sentencesToProcess);
          lastProcessedSentenceIndex.current = sentences.length;
        }
      }
    },
    [processText]
  );

  const processTranscriptionRef = useRef(processTranscription);

  useEffect(() => {
    processTranscriptionRef.current = processTranscription;
  }, [processTranscription]);

  const connectSocket = useCallback(() => {
    const MAX_RETRIES = 5;
    const RETRY_DELAY = 3000; // 3 seconds
    let retryCount = 0;

    const tryConnect = () => {
      console.log(`Attempting to connect to WebSocket (Attempt ${retryCount + 1}/${MAX_RETRIES})`);

      socketRef.current = io('https://transcript.cognevo.co.uk/', {
        transports: ['websocket'],
        upgrade: false,
        secure: true,
        rejectUnauthorized: false,
        reconnection: false, // We'll handle reconnection manually
      });

      socketRef.current.on('connect', () => {
        console.log('WebSocket connected successfully');
        retryCount = 0; // Reset retry count on successful connection
        setIsSocketConnected(true); // Update state when connected
      });

      socketRef.current.on('connect_error', (error: Error) => {
        console.error('WebSocket connection error:', error.message);

        if (retryCount < MAX_RETRIES) {
          retryCount++;
          console.log(`Retrying connection in ${RETRY_DELAY / 1000} seconds...`);
          setTimeout(tryConnect, RETRY_DELAY);
        } else {
          console.error('Max retries reached. Unable to establish WebSocket connection.');
        }
      });

      socketRef.current.on('disconnect', () => {
        console.log('WebSocket disconnected');
        setIsSocketConnected(false);
      });

      socketRef.current.on('transcription_update', (data: { transcription: string }) => {
        setOutputText((prevText) => {
          const newText = prevText + (prevText ? ' ' : '') + data.transcription;
          console.log('Transcription update received:', newText);
          processTranscriptionRef.current(newText);
          return newText;
        });
      });
    };

    tryConnect();
  }, []);

  useEffect(() => {
    connectSocket();

    return () => {
      if (socketRef.current) {
        console.log('Disconnecting WebSocket');
        socketRef.current.off('transcription_update');
        socketRef.current.disconnect();
        setIsSocketConnected(false);
      }
    };
  }, [connectSocket]);


  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isStreaming) {
      words.current = inputText.split(/\s+/);
      wordIndex.current = 0;

      interval = setInterval(() => {
        if (wordIndex.current < words.current.length) {
          setOutputText((prev) => {
            const newText = prev + (prev ? ' ' : '') + words.current[wordIndex.current];
            processTranscription(newText);
            return newText;
          });
          wordIndex.current++;
        } else {
          setIsStreaming(false);
        }
      }, 1000 / wordsPerSecond);
    }
    return () => clearInterval(interval);
  }, [isStreaming, inputText, wordsPerSecond, processTranscription]);

  useEffect(() => {
    if (qaPairs.length > 0) {
      setCurrentQAIndex(qaPairs.length - 1);
    }
  }, [qaPairs]);

  const handleStartStop = () => {
    if (isStreaming) {
      setIsStreaming(false);
    } else {
      setIsStreaming(true);
      setIsRecording(false);
      stopRecording();
      resetState();
    }
  };

  const handleStartStopRecording = async () => {
    if (!isSocketConnected) {
      console.error('Socket is not connected. Cannot start recording.');
      return;
    }

    if (isRecording) {
      await stopRecording();
    } else {
      setIsRecording(true);
      setIsStreaming(false);
      resetState();
      await startRecording();
    }
  };

  const resetState = () => {
    setOutputText('');
    setAnnotatedText('');
    setSentenceCount(0);
    wordIndex.current = 0;
    lastProcessedSentenceIndex.current = 0;
    completeSentences.current = [];
    setQAPairs([]);
    setCurrentQAIndex(0);
    setCurrentSpeaker('');
  };

  const handleSpeedChange = (value: number[]) => {
    setWordsPerSecond(value[0]);
  };

  const handleQANavigation = (direction: number) => {
    setCurrentQAIndex((prevIndex) => {
      const newIndex = prevIndex + direction;
      return Math.max(0, Math.min(newIndex, qaPairs.length - 1));
    });
  };

  async function getMicrophone() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      return new MediaRecorder(stream, { mimeType: 'audio/webm' });
    } catch (error) {
      console.error('Error accessing microphone:', error);
      throw error;
    }
  }

  async function openMicrophone(microphone: MediaRecorder, socket: Socket) {
    return new Promise<void>((resolve) => {
      microphone.onstart = () => {
        console.log('Client: Microphone opened');
        resolve();
      };
      microphone.ondataavailable = async (event) => {
        console.log('Client: Microphone data available:', event.data.size);
        if (event.data.size > 0) {
          socket.emit('audio_stream', event.data);
          console.log('Audio data sent to server');
        }
      };
      microphone.start(1000); // Adjust chunk size if needed (e.g., 500 for 500ms chunks)
    });
  }

  async function startRecording() {
    if (socketRef.current) {
      socketRef.current.emit('toggle_transcription', { action: 'start' });
      microphoneRef.current = await getMicrophone();
      console.log('Client: Waiting to open microphone');
      await openMicrophone(microphoneRef.current, socketRef.current);
    } else {
      console.error('Socket connection not established');
    }
  }

  async function stopRecording() {
    if (microphoneRef.current) {
      microphoneRef.current.stop();
      microphoneRef.current.stream.getTracks().forEach((track) => track.stop());
      if (socketRef.current) {
        socketRef.current.emit('toggle_transcription', { action: 'stop' });
      }
      microphoneRef.current = null;
      setIsRecording(false);
      console.log('Client: Microphone closed');
    }
  }

  return (
    <div className="p-4 max-w-3xl mx-auto bg-gray-100">
      <h1 className="text-2xl font-bold mb-4 text-blue-600">Transcriber</h1>
      <Textarea
        placeholder="Enter text to transcribe..."
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        className="w-full h-32 mb-4 p-2 border border-gray-300 rounded"
      />
      <div className="flex items-center space-x-4 mb-4">
        <Button
          onClick={handleStartStop}
          disabled={!inputText.trim() || isRecording}
          className={`${
            isStreaming ? 'bg-red-500 hover:bg-red-600' : 'bg-blue-500 hover:bg-blue-600'
          } text-white`}
        >
          {isStreaming ? 'Stop Simulation' : 'Start Simulation'}
        </Button>
        <Button
          onClick={handleStartStopRecording}
          disabled={isStreaming}
          className={`${
            isRecording ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'
          } text-white`}
        >
          {isRecording ? 'Stop Recording' : 'Start Recording'}
        </Button>
        <div className="flex-grow flex items-center space-x-2">
          <Slider
            value={[wordsPerSecond]}
            onValueChange={handleSpeedChange}
            min={5}
            max={20}
            step={1}
            className="w-full"
          />
          <span className="text-sm w-20">{wordsPerSecond} words/sec</span>
        </div>
      </div>

      <div className="mb-4">
        <h3 className="font-bold mb-2">Discussion Stream:</h3>
        <div className="border p-4 h-32 overflow-y-auto bg-white rounded">
          <p>{outputText}</p>
        </div>
        <p className="mt-2 text-sm text-gray-600">Sentence count: {sentenceCount}</p>
      </div>

      <div className="mb-4">
        <h3 className="font-bold mb-2">Annotated Discussion Stream:</h3>
        <div className="border p-4 h-48 overflow-y-auto bg-white rounded">
          <p>{annotatedText}</p>
        </div>
      </div>

      <div className="flex justify-between items-center mb-4">
        <span>Current Speaker: {currentSpeaker}</span>
        <div>
          <Button
            onClick={() => handleQANavigation(-1)}
            disabled={currentQAIndex === 0}
            className="mr-2 bg-gray-300"
          >
            Previous
          </Button>
          <Button
            onClick={() => handleQANavigation(1)}
            disabled={currentQAIndex === qaPairs.length - 1}
            className="bg-gray-300"
          >
            Next
          </Button>
        </div>
      </div>
      <div className="border p-4 bg-white rounded mb-4">
        <h3 className="font-bold">Current Question:</h3>
        <p>{qaPairs[currentQAIndex]?.question || 'No questions yet'}</p>
      </div>
      <div className="border p-4 bg-white rounded">
        <h3 className="font-bold">Current Answer:</h3>
        <p>{qaPairs[currentQAIndex]?.answer || 'No answer yet'}</p>
      </div>
    </div>
  );
};

export default App;